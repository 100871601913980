import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import { Button } from '../../components/Common/Button'
import List from '../../components/Common/List'
import { toLink } from '../../utils'

import * as s from '../../pages/news.module.scss'

const WebinarDocsSod: React.FC = () => {
  return (
    <>
      <SEO
        title="SIGNAL DOCS: Больше, чем просто СОД"
        description="SIGNAL DOCS - облачный сервис для управления строительными проектами. Среда общих данных с размещением в облаке или на ваших серверах, выдачей замечаний и согласованием проектной документации. Аналог BIM 360 адаптированный для работы в РФ"
      />
      <NewsSection
        title="SIGNAL DOCS: Больше, чем просто среда общих данных"
        dateData={{ date: '19.02.2024' }}
        rayContent
      >
        <p className={s.text}>
          15 февраля мы провели вебинар, посвященный среде общих данных SIGNAL
          DOCS - облачному сервису с юридически значимым документооборотом.
        </p>
        <div className="flex">
          <StaticImage
            className="mx-auto my-4"
            src="../../assets/images/news/webinar-docs-sod/image-1.png"
            alt="SIGNAL DOCS"
            title="SIGNAL DOCS "
            placeholder="blurred"
          />
        </div>
        <div className="w-100 flex justify-center">
          <Button
            size="large"
            className="my-4"
            onClick={() =>
              toLink('https://www.youtube.com/watch?v=cDG4ITIMqG0')
            }
          >
            Смотреть запись
          </Button>
        </div>
        <p className={s.text}>
          На вебинаре мы подробно показали основной функционал SIGNAL DOCS:
        </p>
        <List className="pl-4">
          <li>Как администрируются хаб и проекты</li>
          <li>Что из себя представляет мобильная версия</li>
          <li>Какие файлы позволяет просматривать</li>
          <li>Как выдаются замечания и согласования</li>
          <li>Подписания документов с помощью ЭЦП и простановка QR-кодов</li>
        </List>
        <p className={s.text}>
          Также представили планы по развитию продукта на этот год и поделились
          стоимостью подписки сервиса. И плюс показали новый функционал DOCS for
          Windows - десктопный клиент для работы с SIGNAL DOCS, аналог Autodesk
          Desktop Connector в BIM 360.
        </p>
        <p className={s.text}>
          Вебинар будет полезен проектировщикам, Главным инженерам и главным
          архитекторам проектов, BIM‑менеджерам, BIM‑координаторам,
          руководителям проектов, техническим директорам, начальникам
          структурных подразделений, представителям департамента IT
        </p>
        <h2 className={s.textTitle}>Спикер:</h2>
        <div className="flex items-center">
          <StaticImage
            width={160}
            src="../../assets/images/news/webinar-docs-sod/Сергей-Казанцев.png"
            alt="Сергей Казанцев"
            title="Сергей Казанцев"
            placeholder="blurred"
          />
          <div>
            <p className={cn(s.text, 'my-1')}>Сергей Казанцев</p>
            <p className={cn(s.text, 'my-1')}>
              {`Директор по цифровизации строительства\nSIGNAL`}
            </p>
          </div>
        </div>
      </NewsSection>
    </>
  )
}

export default WebinarDocsSod
